
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import GButton from '@/components/gsk-components/GskButton.vue';
import MaxWidth from '@/components/MaxWidth.vue';
import EmptyState from '@/components/EmptyState.vue';
import { RouteNames } from '@/constants';
import HelpTooltip from '@/components/HelpTooltip.vue';
import ListingEntry from '@/components/Publish/ListingEntry.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import HelpLink from '@/components/HelpLink.vue';
import { TokensModule } from '@/store/modules/tokens.module';
import UserCircle from '@/components/UserCircle.vue';
import UserList from '@/components/UserList.vue';
import TokenListings from '@/components/tokens/TokenListings.vue';
import { HiddenToken } from '@/types/tokens.types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import img from '@/assets/computer.svg';

@Component({
  components: {
    UserCircle,
    GButton,
    MaxWidth,
    EmptyState,
    HelpTooltip,
    ListingEntry,
    DeleteDialog,
    GDialog,
    HelpLink,
    UserList,
    TokenListings,
  },
})
export default class MyTokensView extends Vue {
  private loading = true;
  private error = false;
  private deleteId = '';
  private openDelete = false;
  public isAdminMode = false;
  public isFeatured = false;
  public listingId = -1;

  get headerStyle() {
    return {
      backgroundColor: 'var(--theme-lightest)',
    };
  }

  get imageSrc() {
    return img;
  }

  get tokens() {
    return TokensModule.tokens;
  }

  get newTokenLink(): RawLocation {
    return { name: RouteNames.NewToken };
  }

  delToken(token: HiddenToken) {
    this.openDelete = true;
    this.deleteId = token.patId;
  }

  async deleteToken(): Promise<void> {
    await TokensModule.deleteToken(this.deleteId)
      .then(() => {
        this.openDelete = false;
        return this.loadTokens();
      })
      .catch(() => {
        this.openDelete = false;
      });
  }

  loadTokens() {
    return TokensModule.getAllTokens()
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  generateNewToken() {}

  async created() {
    if (this.hasLoadedTokens) {
      this.loading = false;
      this.loadTokens();
    } else {
      await this.loadTokens();
    }
  }

  get hasLoadedTokens(): boolean {
    return TokensModule.hasLoadedTokens;
  }
}
