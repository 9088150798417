
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { RawLocation } from 'vue-router';
import GButton from '@/components/gsk-components/GskButton.vue';
import { RouteNames } from '@/constants';
import UserCircle from '@/components/UserCircle.vue';
import UserList from '@/components/UserList.vue';
import { HiddenToken } from '@/types/tokens.types';

@Component({
  components: {
    GButton,
    UserCircle,
    UserList,
  },
})
export default class TokenListings extends Vue {
  @Prop(Object) readonly token!: HiddenToken;

  get expireDate() {
    return formatDistanceToNow(new Date(this.token.expireDate));
  }

  get editLink(): RawLocation {
    return {
      name: RouteNames.EditToken,
      params: {
        tokenId: this.token.patId.toString(),
      },
    };
  }

  @Emit('delete')
  del(token: HiddenToken) {
    return;
  }
}
